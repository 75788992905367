import React from "react";

const About = () => {
  return (
    <section id="about" className="about section">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <span>
          About Us
          <br />
        </span>
        <h2>
          About Us
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who -->
        <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container">
        <div className="row gy-4">
          <div
            class="col-lg-7 order-2 order-lg-2 content"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h3 class="mx-5 my-5">About UK Spouse or Partner Visa</h3>
            <p class="mx-5">
              UK Spouse or Partner Visa is a working partnership with Hunter
              Stone Law who are a leading specialist immigration law firm
              situated in London. With over 45 years of experience and a team of
              highly trained lawyers, we offer comprehensive immigration
              services for individual clients and corporate clients. Our
              extensive knowledge of UK immigration law allows us to deliver
              expert advice and representation, ensuring your application is
              handled with the utmost professionalism and care.
            </p>
          </div>

          <div
            class="col-lg-5 order-1 order-lg-1"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {/* <!-- <h3>Voluptatem dignissimos provident quasi corporis</h3> --> */}
            <img src="assets/img/logo.png" className="img-fluid" alt="" />
          </div>
        </div>

        {/* <!-- cards --> */}
        <div className="row">
          <div class="col-lg-4 col-md-6 mb-4 pt-5">
            <div class="card shadow-sm border-0">
              <div class="card-body">
                <div class="user-content">
                  <h3 class="text-capitalize user-name">Our Experience</h3>
                  <p class="small text-muted mb-0">
                  With over 45 years of combined experience, our team has successfully handled
                  thousands of cases. We have encountered and overcome a wide range of
                  challenges, giving us the insight and skills needed to handle even the complex of
                  cases. We are regulated by the Immigration Advice Authority (IAA) formerly known
                  as the Office of Immigration Services Commissioner (OISC), ensuring that the
                  immigration advice we offer is accurate and reliable.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mb-4 pt-5">
            <div class="card shadow-sm border-0">
              <div class="card-body">
                <div class="user-content">
                  <h3 class="text-capitalize user-name">Our Mission</h3>

                  <p class="small text-muted mb-0">
                    Our mission is to simplify the immigration process and
                    provide you with the confidence and support needed to
                    navigate it successfully. We believe in transparency,
                    integrity, and dedication, and we are committed to helping
                    you achieve your immigration goals.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 mb-4 pt-5">
            <div class="card shadow-sm border-0">
              <div class="card-body">
                <div class="user-content">
                  <h3 class="text-capitalize user-name">Industry Leaders</h3>

                  <p class="small text-muted mb-0">
                    Our highly competent immigration professionals undertake all
                    the work involved through tailored processes designed to
                    meet requirements specific to our clients. Hunter Stone Law is 
                    registered with the IAA (formerly the OISC) to ensure that the
                    immigration advice we offer our clients is accurate and in 
                    accordance with UK regulations and law. You are unique, and your
                    immigration needs should be just that—unique and personal.
                    We provide a service tailored specifically to you and your
                    family, giving you complete peace of mind knowing you are
                    fully protected.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
