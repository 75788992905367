import React, { Fragment } from "react";
import HeroSection from "./hero-section";
import About from "./about";
import Services from "./services";
import OurGaurantee from "./our-gaurantee";
import Contact from "./contact";

const Home = () => {
  return (
    <Fragment>
      <HeroSection />
      <About />
      <OurGaurantee />
      <Services />
      <Contact />


      {/* <Categories />
      <Teams />
      <FrequentlyAsked /> */}
      
    </Fragment>
  );
};

export default Home;
