import Aos from "aos";
import React, { useEffect } from "react";
import "../../styles/guarantee.css"; // Ensure this file is linked to your styles

const Guarantee = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      Aos.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  return (
    <section id="guarantee" className="guarantee-section">
      <div className="container section-title" data-aos="fade-up">
        <span className="section-subtitle">Our Commitment</span>
        <h2 className="section-heading">Our Guarantee</h2>
      </div>
      <div className="container">
        <div className="intro-text text-center" data-aos="fade-up">
          <p>
            At UK Spouse or Partner Visa, we are committed to providing
            exceptional service and ensuring the best possible outcome for your
            visa application. We understand that applying for a UK spouse or
            partner visa can be stressful, and we want to offer you peace of
            mind with our comprehensive guarantee.
          </p>
        </div>
        <div className="guarantee-details" data-aos="fade-up">
          <h3 className="guarantee-title">Our Commitment to You</h3>
          <p className="guarantee-description">
            When you choose UK Spouse or Partner Visa to handle your
            application, you are choosing a team of experts dedicated to your
            success. We are confident in our ability to guide you through the
            process and submit a strong application.{" "}
            <strong>
              To demonstrate our commitment, we offer the following guarantees:
            </strong>
          </p>
          <ul className="guarantee-list">
            <li className="guarantee-item">
              <h4 className="guarantee-item-title">
                Entry Clearance Applications (Outside the UK):
              </h4>
              <ul className="nested-list">
                <li className="nested-item">
                  <span className="bullet">✔</span> Full Refund of Our Fees: If
                  your application is refused, we will refund the full amount of
                  our service fees.
                </li>
                <li className="nested-item">
                  <span className="bullet">✔</span> Refund of Home Office
                  Application Fee: In addition to refunding our fees, we will
                  also reimburse you for the Home Office application fee.
                </li>
              </ul>
            </li>

            <li className="guarantee-item">
              <h4 className="guarantee-item-title">
                In-Country Applications (Extending or Switching to the
                Spouse/Partner Route):
              </h4>
              <ul className="nested-list">
                <li className="nested-item">
                  <span className="bullet">✔</span> Full Refund of Our Fees: If
                  your application is refused, we will refund the full amount of
                  our service fees.
                </li>
                <li className="nested-item">
                  <span className="bullet">✔</span> Comprehensive Appeal
                  Service: We will handle the appeal process for you at no
                  additional cost, covering all expenses associated with the
                  appeal.
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="guarantee-details" data-aos="fade-up">
          <h3 className="guarantee-title">How Our Guarantee Works</h3>
          <ul className="guarantee-list">
            <li className="guarantee-item">
              <h4 className="guarantee-item-title">Initial Assessment:</h4>
              <ul className="nested-list">
                <li className="nested-item">
                  <span className="bullet">✔</span> Our expert team will conduct
                  a thorough assessment of your eligibility and documentation to
                  ensure your application meets all requirements.
                </li>
              </ul>
            </li>

            <li className="guarantee-item">
              <h4 className="guarantee-item-title">Approval to Submit:</h4>
              <ul className="nested-list">
                <li className="nested-item">
                  <span className="bullet">✔</span> Once we are confident that
                  your application is complete and meets the necessary criteria,
                  we will give you the go-ahead to submit your application. At
                  this point, our guarantee takes effect.
                </li>
              </ul>
            </li>

            <li className="guarantee-item">
              <h4 className="guarantee-item-title">Application Outcome:</h4>
              <ul className="nested-list">
                <li className="nested-item">
                  <span className="bullet">✔</span> If your application is
                  successful, we celebrate with you as you take the next step in
                  your journey.
                </li>
                <li className="nested-item">
                  <span className="bullet">✔</span> If your application is
                  refused, we will:
                  <ul className="double-nested-list">
                    <li className="double-nested-item">
                      Refund our service fees in full.
                    </li>
                    <li className="double-nested-item">
                      For entry clearance applications, reimburse the Home
                      Office application fee.
                    </li>
                    <li className="double-nested-item">
                      For in-country applications, initiate the appeal process
                      and cover all related costs.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="guarantee-details" data-aos="fade-up">
          <h3 className="guarantee-title">Terms and Conditions</h3>
          <ul className="terms-list">
            <li className="terms-item">
              <h4 className="terms-item-title">
                Complete and Honest Disclosure:
              </h4>
              <p>
                Our guarantee is based on the completeness and honesty of the
                information and documents you provide. Any omission or
                misrepresentation may void the guarantee.
              </p>
            </li>
            <li className="terms-item">
              <h4 className="terms-item-title">Following Our Guidance:</h4>
              <p>
                You must follow our advice and submit all required documents
                promptly.
              </p>
            </li>
            <li className="terms-item">
              <h4 className="terms-item-title">Application Refusal Reasons:</h4>
              <p>
                The guarantee applies if the refusal is due to reasons within
                our control, such as documentation errors or insufficient
                evidence. It does not cover refusals based on personal conduct,
                criminal history, or fraudulent documentation.
              </p>
            </li>
            <li className="terms-item">
              <h4 className="terms-item-title">Appeal Process:</h4>
              <p>
                For in-country applications, we will manage the appeal process
                from start to finish, including preparation, submission, and
                representation.
              </p>
            </li>
          </ul>
          <p className="terms-link">
            For full terms and conditions, <a href="#">click here</a>.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Guarantee;
