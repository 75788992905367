import Aos from "aos";
import React, { useEffect } from "react";

const HeroSection = () => {
  useEffect(() => {
    Aos.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      Aos.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  return (
    <section id="home" className="hero section">
      <img src="assets/img/banner-sap.png" alt="" data-aos="fade-in" />

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row justify-content-start">
          <div className="col-lg-6">
            <h2>Your Trusted Partner for UK Immigration</h2>
            <p class="col-lg-12 banner-text">
              Navigating the UK immigration process can be complex and overwhelming,
              especially when applying for a spouse or partner visa. At UK Spouse or Partner Visa,
              we specialize in providing expert guidance and support to help you and your loved
              ones reunite in the UK. Our experienced team is here to simplify the process and
              ensure your application has the best chance of success. Whether you’re applying
              first time for the spouse visa, applying for an extension to your current visa, or
              applying for indefinite leave to remain, we can help you obtain your visa.
            </p>
            {/* <!-- <p className="col-lg-8">Welcome to our website, dedicated to providing advice and assistance to parents and guardians who
              want to register their children as British citizens.</p> --> */}
            <a href="#about" className="btn-get-started">
              Get Started
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
