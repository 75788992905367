import React from "react";

const OurGaurantee = () => {
  return (
    <section id="about" className="about section">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <span>
          Gaurantee
          <br />
        </span>
        <h2>
          Our Gaurantee
          <br />
        </h2>
        {/* <!-- <p>Welcome to our website, dedicated to providing advice and assistance to parents and guardians who -->
        <!-- want to register their children as British citizens.</p> --> */}
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container">
        <div className="text-center mb-5">
        <div
            className="content"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h3 className="mx-5 my-5">Your Success, Our Promise</h3>
            <p className="mx-5">
            At Hunter Stone Law, we’re not just experienced – we’re experts. With over thousands of spouse visa applications successfully handled, we have the skills and confidence to deliver exceptional results for you.
            </p>
          </div>
        </div>

        <div className="alert alert-success text-center">
            <h2 className="fw-bold">100% Confidence, 100% Refund Guarantee</h2>
            <p>If your spouse or partner visa application is refused due to an error on our part, we’ll refund your professional fees in full.</p>
            <p><strong>Not only that:</strong> We will also refund your application fee paid to the Home Office. We are the only firm to provide this guarantee. This is more than a guarantee – it’s our commitment to your success.</p>
        </div>

        <div className="row text-center py-5">
            <div className="col-md-3">
                <div className="card border-0 shadow h-100">
                    <div className="card-body d-flex flex-column align-items-center">
                        <div className="icon mb-3">
                            <i className="bi bi-check-circle text-success display-4"></i>
                        </div>
                        <h4 className="card-title">Over 10,000 Applications</h4>
                        <p className="card-text text-muted">Our deep knowledge of UK immigration law ensures you’re in safe hands.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card border-0 shadow h-100">
                    <div className="card-body d-flex flex-column align-items-center">
                        <div className="icon mb-3">
                            <i className="bi bi-trophy text-warning display-4"></i>
                        </div>
                        <h4 className="card-title">Proven Success</h4>
                        <p className="card-text text-muted">We’ve helped thousands of clients achieve their dreams of living with their loved ones in the UK.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card border-0 shadow h-100">
                    <div className="card-body d-flex flex-column align-items-center">
                        <div className="icon mb-3">
                            <i className="bi bi-shield-check text-primary display-4"></i>
                        </div>
                        <h4 className="card-title">Risk-Free Assurance</h4>
                        <p className="card-text text-muted">Your success is our priority. If we make a mistake, you get your money back – no fine print.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card border-0 shadow h-100">
                    <div className="card-body d-flex flex-column align-items-center">
                        <div className="icon mb-3">
                            <i className="bi bi-clipboard-check text-info display-4"></i>
                        </div>
                        <h4 className="card-title">Tailored Guidance</h4>
                        <p className="card-text text-muted">We handle your case from start to finish, ensuring every detail is covered.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="py-5">
            <h3 className="text-center mb-4 display-6 fw-bold">How It Works</h3>
            <div className="row">
                <div className="col-md-4">
                    <div className="card border-0 shadow h-100">
                        <div className="card-body d-flex flex-column align-items-center text-center">
                            <h4 className="card-title fw-bold">Book Your FREE Consultation</h4>
                            <p className="card-text text-muted">Start with a personalised consultation to discuss your case and eligibility. This consultation is free.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card border-0 shadow h-100">
                        <div className="card-body d-flex flex-column align-items-center text-center">
                            <h4 className="card-title fw-bold">Let Us Handle the Process</h4>
                            <p className="card-text text-muted">From document preparation to application submission, we’ll manage every detail.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card border-0 shadow h-100">
                        <div className="card-body d-flex flex-column align-items-center text-center">
                            <h4 className="card-title fw-bold">  Guaranteed Support</h4>
                            <p className="card-text text-muted">With our experience and commitment, we’ll maximise your chances of success. And if things don’t go as planned due to an error on our part, your fees will be refunded.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="text-center py-4">
            <h2 className="fw-bold">🚀 Ready to Begin Your Journey?</h2>
            <p>Join thousands of satisfied clients who have successfully reunited with their loved ones in the UK.</p>
            <a href="#contact" className="btn btn-primary btn-lg">Get Started Today</a>
        </div>
    </div>

    </section>
  );
};

export default OurGaurantee;
