import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const currentHash = location.hash;

  // Function to determine if the link is active
  const isActive = (path, hash = '') => {
    return (currentPath === path && currentHash === hash);
  };

  return (
    <header id="header" className="header fixed-top">
      <div className="branding d-flex align-items-center">
        <div className="container position-relative d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            <h1 className="sitename">UK SPOUSE OR PARTNER VISA</h1>
          </a>

          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <HashLink
                  className={`nav-link ${isActive('/', '#home') ? 'active' : ''}`}
                  to="/#home"
                >
                  Home
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${isActive('/', '#about') ? 'active' : ''}`}
                  to="/#about"
                >
                  About
                </HashLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${isActive('/', '#services') ? 'active' : ''}`}
                  to="/#services"
                >
                  Services
                </HashLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${currentPath === '/visa-requirements' ? 'active' : ''}`}
                  to="/visa-requirements"
                  end
                >
                  Visa Requirements
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${currentPath === '/guarantee' ? 'active' : ''}`}
                  to="/guarantee"
                  end
                >
                  Guarantee
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={`nav-link ${currentPath === '/faqs' ? 'active' : ''}`}
                  to="/faqs"
                  end
                >
                  FAQ
                </NavLink>
              </li>
              <li>
                <HashLink
                  className={`nav-link ${isActive('/', '#contact') ? 'active' : ''}`}
                  to="/#contact"
                >
                  Contact
                </HashLink>
              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
