import React from "react";
import { useForm } from "react-hook-form";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [Message, setMessage] = React.useState("");

  const onSubmit = async (data, e) => {
    console.log(data);
    await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data, null, 2),
    })
      .then(async (response) => {
        let json = await response.json();
        if (json.success) {
          setIsSuccess(true);
          setMessage(json.message);
          e.target.reset();
          reset();
        } else {
          setIsSuccess(false);
          setMessage(json.message);
          e.target.reset();
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setMessage("Client Error. Please check the console.log for more info");
        e.target.reset();
        console.log(error);
      });
  };

  return (
    <section id="contact" class="contact section">
      {/* <!-- Section Title --> */}
      <div class="container section-title" data-aos="fade-up">
        <span>Contact</span>
        <h2>Contact</h2>
        <p>
          For any inquiries or assistance, please get in touch with us through
          our contact form.
        </p>
      </div>
      {/* <!-- End Section Title --> */}

      <div class="container" data-aos="fade-up" data-aos-delay="100">
        <div class="row gy-4">
          <div class="col-lg-3 col-md-6">
            <div
              class="info-item d-flex flex-column justify-content-center align-items-center"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <i class="bi bi-telephone"></i>
              <h3>Call Us</h3>
              <p>+44 20 3137 9097</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div
              class="info-item d-flex flex-column justify-content-center align-items-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <i class="bi bi-envelope"></i>
              <h3>Email Us</h3>
              <p>info@ukspouseorpartnervisa.com</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div
              class="info-item d-flex flex-column justify-content-center align-items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <i class="bi bi-geo-alt"></i>
              <h3>Address</h3>
              <p>Hunter Stone Law, 518 Roman Road, London, E3 5ES</p>
            </div>
          </div>

          <div class="col-lg-2 col-md-6">
            <div
              class="info-item d-flex flex-column justify-content-center align-items-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <i class="bi bi-whatsapp"></i>
              <h3>Chat with us</h3>
              <a
                target="_blank"
                aria-label="Chat on WhatsApp"
                href="https://wa.me/447984713967?text=Hello I am interested to know more about Spouse and Partners VISA" rel="noreferrer"
              >
                Open Chat
              </a>
            </div>
          </div>
        </div>

        <div class="gy-4 mt-5">
          <form
            action="forms/contact.php"
            method="post"
            class="php-email-form"
            data-aos="fade-up"
            data-aos-delay="400"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="hidden"
              value="9cd2221d-1ef5-40ea-8007-3c2290cf7b35"
              {...register("access_key")}
            />
            <input
              type="hidden"
              value="UK SPOUSE OR PARTNER VISA"
              {...register("from_name")}
            />
            <input
              type="checkbox"
              id=""
              className="hidden"
              style={{ display: "none" }}
              {...register("botcheck")}
            ></input>
            <div class="row gy-4">
              <div class="col-md-6">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="Your Name"
                  {...register("name", {
                    required: "Full name is required",
                    maxLength: 80,
                  })}
                />
                {errors.name && (
                  <div className="mt-1 text-red-600">
                    <small>{errors.name.message}</small>
                  </div>
                )}
              </div>

              <div class="col-md-6 ">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  placeholder="Your Email"
                  {...register("email", {
                    required: "Enter your email",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Please enter a valid email",
                    },
                  })}
                />
                {errors.email && (
                  <div className="mt-1 text-red-600">
                    <small>{errors.email.message}</small>
                  </div>
                )}
              </div>

              <div class="col-md-12">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  placeholder="Subject"
                  {...register("subject", {
                    required: "Subject is required",
                    maxLength: 100,
                  })}
                />
                {errors.subject && (
                  <div className="mt-1 text-red-600">
                    <small>{errors.subject.message}</small>
                  </div>
                )}
              </div>

              <div class="col-md-12">
                <textarea
                  class="form-control"
                  name="message"
                  rows="6"
                  placeholder="Message"
                  {...register("message", { required: "Enter your Message" })}
                ></textarea>
                {errors.message && (
                  <div className="mt-1 text-red-600">
                    <small>{errors.message.message}</small>
                  </div>
                )}
              </div>

              <div class="col-md-12 text-center">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                {isSuccess && (
                  <div class="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                )}
                <p className="text-gray-300 md:px-3">{Message}</p>
                <button type="submit">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
